











































import { defineComponent } from '@vue/composition-api'
import useFooter from '@/hooks/layout/useFooter'

export default defineComponent({
  name: 'MobileFooter',
  setup() {
    const bg =
      'https://zyb-zhike-file.cdnjtzy.com/mis/aed3de17-ac67-4cbf-861f-8da5aa77ff40.png'
    const { sliceAbout, handleRedirect, copyright, footerName } = useFooter()
    return {
      bg,
      sliceAbout,
      handleRedirect,
      copyright,
      footerName
    }
  }
})
